import './App.css';

import React, {useState} from 'react';
import Highlight from 'react-highlight';

function App() {
    const [email, setEmail] = useState("");
    const [yetToLoad, setYetToLoad] = useState(true);
    const [body, setBody] = useState("");

    const wfeKey = 'WFEK01FM3KN1REZESD6BEXAMPLEKEY10';
    const init = { headers: { 'WFE-Key': wfeKey }};

    return (
        <div className={"mui-container"}>
            <div className={"mui-panel"}>
                <h1><code>waitfor.email</code></h1>
                <h2>Temporary email addresses in a few lines of code</h2>
                <div className="mui-divider"/>
                <div className={"mui-row"}>
                    <div className={"mui-col-md-6"}>
                        <Highlight className={"language-javascript"}>
                            {`const wfeKey = 'WFEK01FM3KN1REZESD6BEXAMPLEKEY10';
const init = { headers: { 'WFE-Key': wfeKey }};

const r1 = await fetch("https://waitfor.email/create", init);
const { email } = await r1.json();`}
                        </Highlight>
                    </div>
                    <div className={"mui-col-md-6"}>
                        <p style={{paddingTop: "15px"}}>
                            There are two steps. First, create a temporary email address:
                        </p>
                        <p>
                            <button className={"mui-btn mui-btn--primary mui-btn--raised"} disabled={!yetToLoad}
                                    onClick={async () => {
                                        setYetToLoad(false);

                                        const resp = await fetch("/create", init);
                                        const {email} = await resp.json();
                                        setEmail(email);

                                        const recvResp = await fetch(`/receive?email=${email}`, init);
                                        const bodyJson = await recvResp.json();
                                        setBody(JSON.stringify(bodyJson, null, "  "));
                                    }}><code>fetch('https://waitfor.email/create')</code></button>
                        </p>
                    </div>
                </div>

                <div className={"mui-row"}>
                    <div className={"mui-col-md-6"}/>
                    <div className={"mui-col-md-6"}>
                        {email === "" ? <></> : <p>
                            Ok, now send an email to <code><a href={`mailto:${email}`}>{email}</a></code>
                        </p>}
                    </div>
                </div>


                <div className={"mui-row"}>
                    <div className={"mui-col-md-6"}>
                        <Highlight className={"language-javascript"}>
                            {`const r2 = await fetch(\`https://waitfor.email/receive?email=\${email}\`, init);
const message = await r2.json();
console.log(message, null, "  ");`}
                        </Highlight>
                    </div>
                    <div className={"mui-col-md-6"}>
                        <p style={{paddingTop: "15px"}}>
                            Second, wait for an email to be sent to that address. The <code>/receive</code> endpoint
                            will either return the email immediately (if it was sent before you
                            called <code>/receive</code>) or it will <i>loooooong</i>-poll (up to 15 minutes)
                            until the email arrives.
                        </p>
                    </div>
                </div>
            </div>
            <div className={"mui-panel"}>
                {body === "" ?
                    <p>(An email will appear here when one is sent in the above example)</p> :
                    <Highlight className={"language-javascript"}>
                        {body}
                    </Highlight>
                }
            </div>
        </div>
    );
}

export default App;
